import React, { useEffect, useState } from "react"
import "./Loader.css"
import logo from "../../../images/racqy green.svg"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  logo: {
    width: 200,
  },
}))

export default function Loader() {
  const classes = useStyles()
  const [animation, setAnimation] = useState(false)

  useEffect(() => {
    const delay = setTimeout(() => {
      setAnimation(true)
    }, 1000)
    return () => clearTimeout(delay)
  }, [])

  return (
    <Grid class="containerr">
      <Grid
        justifyContent="center"
        alignItems="center"
        container
        className={`${animation && "animate"}`}
      >
        <img
          src={logo}
          className={`${classes.logo} ${animation && "img-move-down"}`}
          alt=""
        />
      </Grid>
      <Grid class={`line ${animation && "inner-line-animation"}`}>
        <Grid class="inner"></Grid>
      </Grid>
    </Grid>
  )
}
