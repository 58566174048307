const COLORS = {
  offBlack: "#0A3133",
  racqyBlue: "#209CA3",
  darkRacqyBlue: "#199198",
  veryDarkRacqyBlue: "#105457",
  racqyLightBlue: "#E0EFF0",
  offWhite: "#FAFAFA",
  lightPink: "#FFDBE1",
  pink: "#FD6E84",
  green: "#45C165",
  lightGreen: "#E5F5D9",
  darkGreen: "#6CB430",
  lightGray: "#E0E1E2",
  veryLightGray: "#F7F7F8",
  darkGray: "#AFB2B4",
  disabled: "#DBE0E5",
  darkGray2: "#4E4E4E",
  darkGray3: "#8B8D8E",
  darkGray4: "#BBBDC1",
  darkGray5: "#B7B7BF",
  white: "#FFFFFF",
  shadowColor: "#4A6177",
  clubPurple: "#7A41ED",
  lightColor: "#C6CAD0",
  inputBorder: "#E1E1E7",
  inputPlaceholder: "#AFB2B4",

  red: "#FD6E84",
}
export { COLORS }
