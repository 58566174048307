import React, { useEffect, useState } from "react"
import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import Backdrop from "@material-ui/core/Backdrop"
import Premium from "../../../../static/images/premium-feature.svg"
import CrossIcon from "../../../../static/images/cross-icon-racqy.svg"
import { COLORS } from "../../../utils/theme"
import Button from "@material-ui/core/Button"
import { useSelector, useDispatch } from "react-redux"

import { makeStyles, Typography } from "@material-ui/core"
import {
  activeSubscription,
  changeTrialEndModalStatus,
  setTrialModal,
} from "../../../redux/slices/subscriptionModalSlice"
import AddCardModal from "./AddCardModal"
import Loader from "../Loader/Loader"

const useStyles = makeStyles(theme => ({
  beginButton: {
    width: "40%",
    minWidth: "100px",
    minHeight: "40px",
    position: "absolute",
    bottom: "90px",
    fontSize: "12px",
    color: "white !important",
    textTransform: "capitalize !important",
    borderRadius: "8px !important",
    margin: "15px",
    background: "#45C165",
    "&:hover": {
      background: "#45C165",
    },
    padding: "3px 0px",
  },
  createButton: {
    width: "40%",
    minWidth: "100px",
    minHeight: "40px",
    position: "relative",
    bottom: "90px",
    fontSize: "12px",
    color: "white !important",
    textTransform: "capitalize !important",
    borderRadius: "8px !important",
    margin: "15px",
    background: "#45C165",
    "&:hover": {
      background: "#45C165",
    },
    padding: "3px 0px",
  },
  closeButton: {
    width: "40%",
    minWidth: "100px",
    minHeight: "40px",
    position: "relative",
    bottom: "90px",
    fontSize: "12px",
    color: "black !important",
    textTransform: "capitalize !important",
    borderRadius: "8px !important",
    border: "1px solid black",
    margin: "15px",
    background: "Transparent",
    "&:hover": {
      background: "#45C165",
    },
    padding: "3px 0px",
  },
  footer: {
    width: "100%",
    backgroundColor: "#F4F5F7",
    height: "70px",
    padding: "2px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: COLORS.white,
    border: "0px transparent",
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: 24,
    paddingTop: "20px",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "500px" /* Set a fixed height */,
    overflowY: "auto",
  },
  ul: {
    color: COLORS.offBlack,
    fontWeight: "bold",
  },
  crossContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "20px",
  },
  crossIcon: {
    height: "16px",
    width: "16px",
    marginRight: "20px",
    cursor: "pointer",
  },
  premiumIcon: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    marginBottom: "20px",
  },
  "&:focus": {
    outline: "none",
  },
}))

export default function TrialSubscriptionModal() {
  const classes = useStyles()

  const dispatch = useDispatch()
  const trialModal = useSelector(
    state => state.subscriptionModalSlice.showTrialModal
  )
  const [addCard, setAddCard] = useState(false)

  const billingInfo = useSelector(
    state => state.subscriptionModalSlice.billingInfo
  )
  const [trialPeriodStatus, setTrialPeriodStatus] = useState("")

  const loading = useSelector(state => state.subscriptionModalSlice.loading)

  const closeModal = () => {
    dispatch(setTrialModal(false))
  }

  const subscriptionHandler = () => {
    if (billingInfo?.card) {
      dispatch(activeSubscription())
      closeModal()
    } else {
      setAddCard(true)
    }
  }
  const checkTrialPeriod = () => {
    try {
      if (!billingInfo.showTrialEndModal) {
        setTrialPeriodStatus("Trial")
      } else {
        setTrialPeriodStatus("TrialEnd")
      }
    } catch (err) {
      console.log("err", err)
    }
  }
  const onCloseCard = () => {
    setAddCard(false)
  }
  useEffect(() => {
    if (billingInfo) {
      checkTrialPeriod()
    }
  }, [billingInfo, trialPeriodStatus])

  useEffect(() => {
    return () =>
      trialPeriodStatus != "Trial"
        ? dispatch(changeTrialEndModalStatus({ status: false }))
        : null
  }, [])
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={trialModal}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={true}>
        <Box border={"none"} className={classes.box}>
          <div className={classes.crossContainer} onClick={closeModal}>
            <img src={CrossIcon} className={classes.crossIcon} alt="" />
          </div>
          <div
            style={{
              padding: "0px 20px",
              height: "100%",
            }}
          >
            <div className={classes.premiumIcon}>
              <img
                src={Premium}
                style={{
                  height: "40px",
                  width: "40px",
                }}
                alt=""
              />
            </div>
            <Typography
              style={{ fontFamily: "Jakarta" }}
              id="transition-modal-title"
              variant="h4"
              component="h4"
            >
              {trialPeriodStatus == "Trial"
                ? "You are on a 30-day Trial"
                : "Your free trial has ended"}
            </Typography>
            <Typography
              id="transition-modal-description"
              style={{ marginTop: "50px" }}
              sx={{ mt: 10 }}
            >
              {trialPeriodStatus == "Trial"
                ? "What you get with Plus subscription."
                : "You can continue to use Racqy without hese features:"}
            </Typography>
            <ul
              style={{
                padding: "0px 20px",
                lineHeight: "30px",
                color: COLORS.green,
              }}
            >
              <li>
                {" "}
                <span className={classes.ul}>Create venue activities </span>
              </li>

              <li>
                <span className={classes.ul}>Send notifications to users</span>
              </li>
              <li>
                <span className={classes.ul}>
                  Add more users to manage your venue account
                </span>
              </li>
              <li>
                <span className={classes.ul}>
                  Unlimited bookings and products can be added
                </span>
              </li>
            </ul>
          </div>

          {!loading && (
            <>
              {trialPeriodStatus != "Trial" ? (
                <div
                  style={{
                    width: "90%",
                    flexDirection: "row",
                    display: "flex",
                    marginTop: "70px",
                    justifyContent: "space-around",
                    position: "fixed",
                    bottom: "0",
                  }}
                >
                  <Button
                    color="yellow"
                    className={classes.closeButton}
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                  <Button
                    color="yellow"
                    className={classes.createButton}
                    onClick={subscriptionHandler}
                  >
                    Upgrade Plan
                  </Button>
                </div>
              ) : (
                <Button
                  color="yellow"
                  className={classes.beginButton}
                  onClick={closeModal}
                  style={{ width: "90%" }}
                >
                  Begin
                </Button>
              )}
            </>
          )}
          {loading && <Loader />}
          {addCard && <AddCardModal onClose={onCloseCard} />}
          <div className={classes.footer}>
            <Typography
              id="transition-modal-description"
              style={{
                color: COLORS.darkGray3,
                fontSize: "13px",

                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              {trialPeriodStatus == "Trial"
                ? "After 30 days these functions will no be available if you donot upgrade plan"
                : "Payments handled through stripe and renewed every 30 days"}
            </Typography>
          </div>
        </Box>
      </Fade>
    </Modal>
  )
}
