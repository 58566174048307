import React, { useEffect, useState } from "react"
import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"
import Backdrop from "@material-ui/core/Backdrop"
import CrossIcon from "../../../../static/images/cross-icon-racqy.svg"
import { COLORS } from "../../../utils/theme"
import { activeSubscription, setLoading } from "../../../redux/slices/subscriptionModalSlice"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"
import "../Stripe/stripform.css"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles, Typography, InputLabel } from "@material-ui/core"
import { addPaymentCard } from "../../../redux/slices/subscriptionModalSlice"
import Spinner from "../Loader/Spinner"
import DrawerSelect from "../../Schedule/Drawer/Utils/DrawerSelect"
import { VAT_TYPES } from "../../../utils/constants"

const useStyles = makeStyles(theme => ({
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    backgroundColor: COLORS.white,
    border: "0px",
    overflow: "hidden",
    borderRadius: "10px",
    paddingTop: "20px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // height: "80vh" /* Set a fixed height */,
    overflowY: "auto",
  },
  crossContainer: {
    width: "3%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "20px",
  },
  crossIcon: {
    height: "16px",
    width: "16px",
    marginRight: "20px",
    cursor: "pointer",
  },
  labelStyle: {
    color: "#0A3133",
  },
  "&:focus": {
    outline: "none",
  },
}))
export default function AddCardModal(props) {
  const onClose = () => {}

  const classes = useStyles()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true)
  const cardDetail = useSelector(
    state => state.subscriptionModalSlice.billingInfo
  )
  const loading = useSelector(state => state.subscriptionModalSlice.loading)

  const closeModal = () => {
    setVisible(false)
    props.onClose()
  }

  const stripe = useStripe()
  const elements = useElements()
  const userState = useSelector(state => state.userSlice?.user)

  const [billingDetail, setBillingDetail] = useState({
    name: "",
    address: "",
    vatNumber: "",
    vatType: "",
  })

  const onChange = e => {
    setBillingDetail({ ...billingDetail, [e.target.name]: e.target.value })
  }

  const handlePayment = async event => {
    try {
      event.preventDefault()

      dispatch(setLoading(true))


      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        card: elements.getElement(CardExpiryElement),
        card: elements.getElement(CardCvcElement),
        billing_details: {
          name: userState.displayName || billingDetail.name,
          email: userState.email || "",
          address: {
            city: null,
            country: null,
            line1: billingDetail.address,
            line2: null,
            postal_code: "54545",
            state: null,
          },
        },
      })

      dispatch(addPaymentCard({ ...paymentMethod, ...billingDetail }))
    } catch (err) {
      console.log("err0000-", err)
    }
  }

  const onSubscribe = async () => {
    dispatch(activeSubscription())
    onClose()
  }

  useEffect(() => {
    if (cardDetail?.card) {
      onSubscribe()
    }
  }, [cardDetail?.card])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={visible}
      onClose={closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box border={"none"} className={classes.box}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              width: "80%",
              marginLeft: "40px",
            }}
          >
            <Typography id="transition-modal-title" variant="h4" component="h4">
              Add payment method
            </Typography>
          </div>
          <div className={classes.crossContainer} onClick={closeModal}>
            <img src={CrossIcon} className={classes.crossIcon} alt="" />
          </div>
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <form
            style={{ height: "430px", padding: "20px 30px 30px 30px" }}
            onSubmit={handlePayment}
          >
            <div
              style={{
                marginTop: "40px ",
                padding: "0px 10px",
              }}
            >
              <InputLabel shrink className={classes.labelStyle}>
                Name
              </InputLabel>
              <input
                name="name"
                placeholder="name"
                className={"rowPaymentInput"}
                style={{ width: "85%" }}
                onChange={e => onChange(e)}
              />
              <InputLabel shrink className={classes.labelStyle}>
                Address
              </InputLabel>
              <input
                name="address"
                style={{ width: "85%" }}
                placeholder="address"
                className={"rowPaymentInput"}
                onChange={e => onChange(e)}
              />

              {/* <div style={{ width: "100%", marginLeft: "0px !important" }}>
                <DrawerSelect
                  customStyles={{ marginLeft: "0px" }}
                  data={VAT_TYPES}
                  onChange={value =>
                    setBillingDetail({ ...billingDetail, vatType: value.value })
                  }
                  value={VAT_TYPES.find(
                    item => item.value == billingDetail.vatType
                  )}
                  placeHolder="VAT Type"
                  label="VAT Type"
                />
              </div>
              <div style={{ marginTop: "10px" }}></div>
              <InputLabel shrink className={classes.labelStyle}>
                VAT Number
              </InputLabel>
              <input
                name="vatNumber"
                style={{ width: "85%" }}
                placeholder="VAT Number"
                className={"rowPaymentInput"}
                onChange={e => onChange(e)}
              /> */}
              <InputLabel shrink className={classes.labelStyle}>
                Card Number
              </InputLabel>
              <CardNumberElement className={"rowPaymentInputFullWidth"} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginRight: "20px", width: "40%" }}>
                  <InputLabel shrink className={classes.labelStyle}>
                    CVC
                  </InputLabel>
                  <CardCvcElement className={"rowPaymentInput"} />
                </div>

                <div style={{ width: "40%", marginRight: "25px" }}>
                  <InputLabel shrink className={classes.labelStyle}>
                    Exp
                  </InputLabel>
                  <CardExpiryElement className={"rowPaymentInput"} />
                </div>
              </div>
            </div>

            <div style={{ marginBottom: "30px" }}>
              <div className={"btnContainer"}>
                {!loading ? (
                  <button className="button" type="submit">
                    {"Submit"}
                  </button>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  )
}
